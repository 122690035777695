<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <UserValidSettingForm
      v-if="!isLoading"
      :userValidSetting="userValidSetting"
      v-on:addOrUpdateUserValidSetting="addOrUpdateUserValidSetting()"
      :submitName="$t('add')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import { STATUS } from "@/utils/constants";
import UserValidSettingForm from "./../../../components/settings/userValidSettings/UserValidSettingForm.vue";
import UserValidSetting from "./../../../models/settings/userValidSettings/UserValidSetting";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    UserValidSettingForm,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
      userValidSetting: new UserValidSetting(),
    };
  },
  methods: {
    async addOrUpdateUserValidSetting() {
      this.isLoading = true;
      try {
        const response =
          await this.userValidSetting.addOrUpdateUserValidSetting(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.userValidSetting.setInitialValue();
          this.showMsg(response.data.msg, true);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {},
};
</script>
